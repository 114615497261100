@import "../variables/variables";

.home {
    min-height: 100vh;
    display: flex;
    color: $text-black-900;
}

.home .home-info {
    flex:  0 0 60%;
    max-width: 60%;
}

h3.hello {
    font-size: 28px;
    margin: 15px 0;
}

h3.hello span {
    font-size: 30px;
    color: $skin-color;
}

h3.my-profession {
    font-size: 30px;
    margin: 15px 0;
}

.typing {
    color: $skin-color;
}

.home-info p {
    margin-bottom: 70px;
    font-size: 20px;
    color: $text-black-700;
}

.home .home-img {
    flex: 0 0 40%;
    max-width: 40%;
    text-align: center;
    position: relative;
}

.home .home-img::after {
    content: '';
    position: absolute;
    height: 80px;
    width: 80px;
    bottom: 5px;
    border-bottom: 10px solid $skin-color;
    border-right: 10px solid $skin-color;
}

.home .home-img::before {
    content: '';
    position: absolute;
    height: 80px;
    width: 80px;
    left: -50px;
    top: -80px;
    border-top: 10px solid $skin-color;
    border-left: 10px solid $skin-color;
}

.padd-15 {
    padding-left: 15px;
    padding-right: 15px;
}

.home .home-img img {
    margin: auto;
    border-radius: 5px;
    width: 90%;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
}

.btn {
    font-size: 15px;
    font-weight: 500p;
    padding: 12px 35px;
    background: $skin-color;
    color: white;
    border-radius: 40px;
    display: inline-block;
    white-space: nowrap;
    transition: all 0.3s ease;
    border: none;
}

.btn:hover {
    transform: scale(1.05);
}


// mobile 

@media(max-width:900px) { 
    .btn {
        margin-bottom: 40px;
    }
}


// Dark Mode 

#dark {
    .home {
        color: $dark-text-black-900;
    }
    h3.hello span {
        color: $orange-skin-color;
    }
    .typing {
        color: $orange-skin-color;
    }   
    .home-info p {
        color: $dark-text-black-700;
    }  
    .home .home-img::after {
        border-bottom: 10px solid $orange-skin-color;
        border-right: 10px solid $orange-skin-color;
    }
    .home .home-img::before {
        border-top: 10px solid $orange-skin-color;
        border-left: 10px solid $orange-skin-color;
    }
    .btn {
        background: $orange-skin-color;
    }
}
