@import "../variables/variables";

.section {
    background: $bg-black-900;
    min-height: 100vh;
    display: block;
    padding: 0 30px;
    opacity: 1;
    position: fixed;
    left: 270px;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.3s ease;
}

.section-title
{
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 60px;
    margin-top: 40px;
}

.section-title h2 {
    font-size: 40px;
    color: $text-black-900;
    font-weight: 700;
    position: relative;
}

.section-title h2::before
{
    content: '';
    height: 4px;
    width: 50px;
    background-color: $skin-color;
    position: absolute;
    top: 100%;
    left: 0;
}
.section-title h2::after
{
    content: '';
    height: 4px;
    width: 25px;
    background-color: $skin-color;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 8px;
}

.aside {
    width: 270px;
    background: $bg-black-100;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    padding: 30px;
    display: flex;
    border-right: 1px solid $bg-black-50;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.aside .logo {
    position: absolute;
    top: 50px;
    font-size: 30px;
}

.aside .logo a {
    color: $text-black-900;
    font-weight: 700;
    padding: 15px 20px;
    font-size: 30px;
    letter-spacing: 5px;
}

.aside .logo a::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-bottom: 5px solid $skin-color;
    border-left: 5px solid $skin-color;
    bottom: 0;
    left: 0;
}

.aside .logo a span {
    font-size: 40px;
}

.aside .logo a::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-top: 5px solid $skin-color;
    border-right: 5px solid $skin-color;
    top: 0;
    right: 0;
}

.nav-toggler {
    height: 40px;
    width: 45px;
    border: 1px solid $bg-black-50;
    cursor: pointer;
    position: fixed;
    left: 300px;
    top: 20px;
    border-radius: 5px;
    background: $bg-black-100;
    display: none;
    align-items: center;
    justify-content: center;
}

.nav-toggler span {
    height: 2px;
    width: 18px;
    background:  $skin-color;
    display: inline-block;
    position: relative;
}

.nav-toggler span::before {
    content: '';
    height: 2px;
    width: 18px;
    background: $skin-color;
    position: absolute;
    top: -6px;
    left: 0;
}

.nav-toggler span::after {
    content: '';
    height: 2px;
    width: 18px;
    background: $skin-color;
    position: absolute;
    bottom: -6px;
    left: 0;
}

.aside .nav {
    margin-top: 50px;
}

.aside .nav li {
    margin-bottom: 20px;
    display: block;
}

.aside .nav li a {
    font-size: 16px;
    font-weight: 500;
    display: block;
    border-bottom: 2px solid $bg-black-50;
    color: $text-black-900;
    padding: 5px 15px;
}

.aside .nav li a svg {
    margin-right: 15px;
}

.github-link {
    text-align: center;
    position: relative;
    bottom: -200px;
}

.github-link a {
    color: $text-black-900;
    font-size: 35px;
}



// Dark Mode

#dark {
    .section {
        background: $dark-bg-black-900;
    }
    .section-title h2 {
        color: $dark-text-black-900;
    }
    .section-title h2::before {
    background-color: $orange-skin-color;
    }
    .section-title h2::after {
    background-color: $orange-skin-color;
    }
    .aside {
        background: $dark-bg-black-100;
        border-right: 1px solid $dark-bg-black-50;
    }
    .aside .logo a {
        color: $dark-text-black-900;
    } 
    .aside .logo a::before {
    border-bottom: 5px solid $orange-skin-color;
    border-left: 5px solid $orange-skin-color;
    }
    .aside .logo a::after {
        border-top: 5px solid $orange-skin-color;
        border-right: 5px solid $orange-skin-color;
    }
    .aside .nav li a {
        border-bottom: 2px solid $dark-bg-black-50;
        color: $dark-text-black-900;
    }
    .nav-toggler {
        background-color: $dark-bg-black-100;
        border: 1px solid $dark-bg-black-50;
    }
    .nav-toggler span,
    .nav-toggler span::after,
    .nav-toggler span::before {
        background-color: $orange-skin-color;
    }
    .github-link a {
        color: $dark-text-black-900;
    }
} 
