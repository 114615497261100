@import "../variables/variables";

.about .about-content {
    flex: 0 0 100%;
    max-width: 100%;
}
.about .about-content .about-text {
    flex: 0 0 100%;
    max-width: 100%;
}
.about .about-content .about-text h3 {
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: 700;
    color: $text-black-900;
}
.about .about-content .about-text h3 span {
    color: $skin-color;
}
.about .about-content .about-text p {
    font-size: 16px;
    line-height: 25px;
    color: $text-black-700;
}
.about .about-content .personal-info {
    flex: 0 0 60%;
    max-width: 60%;
    margin-top: 40px;
}
.about .about-content .personal-info .info-item {
    flex: 0 0 50%;
    max-width: 50%;
}
.about .about-content .personal-info .info-item p {
    font-weight: 600;
    padding: 10px 0;
    font-size: 16px;
    color: $text-black-900;
    border-bottom: 2px solid $bg-black-50;
}
.about .about-content .personal-info .info-item p span {
    font-weight: 400;
    color: $text-black-700;
    margin-left: 4px;
    display: inline-block;
}
.about .about-content .personal-info .buttons {
    margin-top: 30px;
}
.about .about-content .personal-info .buttons .btn {
    margin-top: 10px;
}
.about .about-content .skills {
    flex: 0 0 40%;
    max-width: 40%;
    margin-top: 40px;
}
.about .about-content .skills .skill-item {
    flex: 0 0 100%;
    max-width: 100%;
}
.about .about-content .skills .skill-item h5 {
    line-height: 40px;
    font-weight: 600;
    font-size: 16px;
    color: $text-black-900;
    text-transform: capitalize;
}
.about .about-content .skills .skill-item .progress {
    background-color: $bg-black-50;
    height: 7px;
    border-radius: 4px;
    width: 100%;
    position: relative;
}
.about .about-content .skills .skill-item {
    margin-bottom: 25px;
}
.about .about-content .skills .skill-item .progress .progress-in {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 4px;
    background-color: $skin-color;
}
.about .about-content .skills .skill-item .skill-percent {
    position: absolute;
    right: 0;
    color: $text-black-900;
    top: -40px;
    font-weight: 400;
    line-height: 40px;
}
.about .about-content .education,
.about .about-content .experience {
    flex: 0 0 50%;
    max-width: 50%;
    margin-top: 30px;
}
.about .about-content h3.title {
    font-size: 24px;
    margin-bottom: 30px;
    font-weight: 700;
    color: $text-black-900;
}
.about .about-content .timeline-box {
    flex: 0 0 100%;
    max-width: 100%;
}
.about .about-content .timeline {
    box-shadow: rgba(214, 214, 214, 0.25) 0px 2px 5px 2px, rgba(158, 155, 155, 0.3) 2px 1px 7px 3px;
    background-color: $bg-black-100;
    padding: 30px 15px;
    border: 1px solid $bg-black-50;
    border-radius: 10px;
    width: 100%;
    position: relative;
}
.about .about-content .timeline .timeline-item {
    position: relative;
    padding-left: 37px;
    padding-bottom: 50px;
}
.about .about-content .timeline .timeline-item:last-child {
    padding-bottom: 0;
}
.about .about-content .timeline .timeline-item::before {
    content: '';
    width: 2px;
    position: absolute;
    height: 100%;
    left: 7px;
    top: 0;
    background-color: $skin-color;
}
.about .about-content .timeline .circle-dot {
    position: absolute;
    left: 0;
    top: 0;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: $skin-color;
}
.about .about-content .timeline .timeline-date {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 12px;
    color: $text-black-700;
}
.about .about-content .timeline .timeline-date .fa {
    margin-right: 5px;
}
.about .about-content .timeline .timeline-title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 15px;
    text-transform: capitalize;
    color: $text-black-900;
}
.about .about-content .timeline .timeline-text {
    line-height: 25px;
    font-size: 16px;
    text-align: justify;
    color: $text-black-700;
}

// Dark Mode

#dark {
    .about .about-content .about-text h3 {
        color: $dark-text-black-900;
    }
    .about .about-content .about-text h3 span {
        color: $orange-skin-color;
    }
    .about .about-content .about-text p {
        color: $dark-text-black-700;
    }
    .about .about-content .personal-info .info-item p {
        color: $dark-text-black-900;
        border-bottom: 2px solid $dark-bg-black-50;
    }
    .about .about-content .personal-info .info-item p span {
        color: $dark-text-black-700;
    }
    .about .about-content .skills .skill-item h5 {
        color: $dark-text-black-900;
    }
    .about .about-content .skills .skill-item .progress {
        background-color: $dark-bg-black-50;
    }
    .about .about-content .skills .skill-item .progress .progress-in {
        background-color: $orange-skin-color;
    }
    .about .about-content .skills .skill-item .skill-percent {
        color: $dark-text-black-900;
    }
    .about .about-content h3.title {
        color: $dark-text-black-900;
    }
    .about .about-content .timeline {
        background-color: $dark-bg-black-100;
        border: 1px solid $dark-bg-black-50;
    }
    .about .about-content .timeline .timeline-item::before {
        background-color: $orange-skin-color;
    }
    .about .about-content .timeline .circle-dot {
        background-color: $orange-skin-color;
    }
    .about .about-content .timeline .timeline-date {
        color: $dark-text-black-700;
    }
    .about .about-content .timeline .timeline-title {
        color: $dark-text-black-900;
    }
    .about .about-content .timeline .timeline-text {
        color: $dark-text-black-700;
    }
}
