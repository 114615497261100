
// Light Mode
$bg-black-900: #f2f2fc;
$bg-black-100: #fdf9ff;
$bg-black-50: #e8dfec;
$text-black-900: #302e4d;
$text-black-700: #504e70;

// Dark Mode
$dark-bg-black-900:#151515;
$dark-bg-black-100:#222222;
$dark-bg-black-50:#393939;
$dark-text-black-900:#ffffff;
$dark-text-black-700:#e9e9e9;

// Skin Colors
$skin-color: #ec1839;
$orange-skin-color:#fa5b0f;