@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
@import "./variables/variables";
@import "./components/sideBar";
@import "./components/home";
@import "./components/about";
@import "./components/projects";
@import "./components/contact";


*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
  
body {
    line-height: 1.6;
    font-family: 'Poppins', sans-serif;
    background-color: #fefefe;
}
  
ul {
    list-style-type: none;
}
  
a {
    text-decoration: none;

}
  
h1,
h2 {
    font-weight: bold;
    line-height: 1.2;
}
  
p {
    margin: 0;
}
  
img {
    width: 100%;
}


.main-content {
    padding-left: 270px;
}

.container {
    max-width: 1100px;
    width: 100%;
    margin: auto;
}

.section .container {
    padding-top: 60px;
    padding-bottom: 70px;
}



// Switcher

.switch {
    margin-left: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.label {
    margin-top: 20px;
    margin-right: 25px;
    font-size: 16px;
    color: white;
    z-index: 1;
    position: relative;
    font-weight: bold;
    margin-bottom: -5px;
}


#light {

    .label {
        color: $text-black-900;
    }
}

#dark {

    .MuiSwitch-thumb {
        background-color: $orange-skin-color;
    }
}


// Nav Link Active Class

#dark .active {
    color: $orange-skin-color !important;
}

#light .active {
    color: $skin-color;
}


@media(max-width:1199px) {
    .section .container {
        padding-top: 70px;
    }
    .aside {
        left: 0;
        max-width: 500px;
        box-sizing: border-box;
        width: 100%;
    }
    .desktop {
        display: none;
    }
    .mobile {
        transition: ease-in;
    }
    .nav-toggler {
        display: flex;
        z-index: 11;
        left: 80%;
    }
    .section {
        left: 0;
    }
    .about .about-content .personal-info .info-item p span {
        display: block;
        margin-left: 0;
    }
    .label {
        font-size: 13px;
        margin-top: 10px;
        margin-left: 10px;
    }
    .switch label {
        width: 120px;
    }

    .github-link {
        bottom: -50px;
    }
}

@media(max-width:991px) {
    .contact .contact-info-item,
    .portfolio .portfolio-item,
    .service .service-item {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .home .home-info {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .home .home-img {
        flex: none;
        max-width: 100%;
    }
    .home .home-img img {
        height: auto;
    }
    .home .home-img::after {
        display: none;
    }
    
    .home .home-img::before {
        display: none;
    }
    .switch {
        margin-left: 0;
        position: absolute;
    }
}
@media(max-width:767px) {
    .contact .contact-form .col-6,
    .contact .contact-info-item,
    .portfolio .portfolio-item,
    .service .service-item,
    .about .about-content .experience,
    .about .about-content .education,
    .about .about-content .skills,
    .about .about-content .personal-info {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .contact .contact-info-item {
        margin-bottom:  10px;
    }
    .section-title {
        margin-bottom: 40px;
    }
    .about .about-content .personal-info .info-item span {
        word-break: break-all;
    }
}







