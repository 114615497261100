@import "../variables/variables";

.portfolio .container {
    padding-bottom: 40px;
}

.portfolio .portfolio-heading {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 40px;
}

.portfolio .portfolio-heading h2 {
    color: $text-black-900;
    font-weight: 500;
}

.portfolio .portfolio-item {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    margin-bottom: 30px;
}

.portfolio .portfolio-item-inner {
    border: 6px solid $bg-black-100;
    border-radius: 10px;
    overflow: hidden;
}

.portfolio .portfolio-item-inner .portfolio-img img {
    width: 100%;
    display: block;
    height: 250px;
}

.project-info {
    background-color: $bg-black-100;
}

.project-info h4 {
    color: $text-black-900;
    font-size: 20px;
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    text-align: center;
    border-bottom: 2px solid $bg-black-50;
}

.project-info h5 {
    font-size: 15px;
    margin-top: 10px;
    color: $text-black-900;
}

.stack {
    color: $text-black-700;
    display: flex;
}

.stack-description p {
    padding-right: 15px;
}

.stack li {
    margin-left: 15px;
    padding-right: 5px;
    margin-top: 5px;
}

.stack li::marker {
    color: $skin-color;
}

.stack ul li::before {
    content: "\2022";
    color: $skin-color;
    font-weight: bold; 
    display: inline-block; 
    width: 1em; 
    margin-left: -1.18em; 
}

.stack-used li {
    margin-top: 10px;
}

.project-links {
    display: flex;
    justify-content: space-evenly;
    padding: 20px 0;
    font-weight: 500;
}

.project-links a {
    color: $text-black-700;
    border-bottom: 2px solid $bg-black-50;
}

// Dark Mode

#dark {
    .portfolio .portfolio-item-inner {
        border: 6px solid $dark-bg-black-100;
    }
    .portfolio .portfolio-heading h2 {
        color: $dark-text-black-900;
    }
    .project-info {
        background-color: $dark-bg-black-100;
    }
    .project-info h4 {
        color: $dark-text-black-900;
        border-bottom: 2px solid $bg-black-50;
    }
    .project-info h5 {
        color: $dark-text-black-900;
    }
    .stack {
        color: $dark-text-black-700;
    }  
    .stack li::marker {
        color: $orange-skin-color;
    }
    .stack ul li::before {
        color: $orange-skin-color;
    }
    .project-links a {
        color: $dark-text-black-700;
        border-bottom: 2px solid $bg-black-50;
    }
}
