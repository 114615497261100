@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
.section {
  background: #f2f2fc;
  min-height: 100vh;
  display: block;
  padding: 0 30px;
  opacity: 1;
  position: fixed;
  left: 270px;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.3s ease;
}

.section-title {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 60px;
  margin-top: 40px;
}

.section-title h2 {
  font-size: 40px;
  color: #302e4d;
  font-weight: 700;
  position: relative;
}

.section-title h2::before {
  content: "";
  height: 4px;
  width: 50px;
  background-color: #ec1839;
  position: absolute;
  top: 100%;
  left: 0;
}

.section-title h2::after {
  content: "";
  height: 4px;
  width: 25px;
  background-color: #ec1839;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 8px;
}

.aside {
  width: 270px;
  background: #fdf9ff;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  padding: 30px;
  display: flex;
  border-right: 1px solid #e8dfec;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.aside .logo {
  position: absolute;
  top: 50px;
  font-size: 30px;
}

.aside .logo a {
  color: #302e4d;
  font-weight: 700;
  padding: 15px 20px;
  font-size: 30px;
  letter-spacing: 5px;
}

.aside .logo a::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-bottom: 5px solid #ec1839;
  border-left: 5px solid #ec1839;
  bottom: 0;
  left: 0;
}

.aside .logo a span {
  font-size: 40px;
}

.aside .logo a::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-top: 5px solid #ec1839;
  border-right: 5px solid #ec1839;
  top: 0;
  right: 0;
}

.nav-toggler {
  height: 40px;
  width: 45px;
  border: 1px solid #e8dfec;
  cursor: pointer;
  position: fixed;
  left: 300px;
  top: 20px;
  border-radius: 5px;
  background: #fdf9ff;
  display: none;
  align-items: center;
  justify-content: center;
}

.nav-toggler span {
  height: 2px;
  width: 18px;
  background: #ec1839;
  display: inline-block;
  position: relative;
}

.nav-toggler span::before {
  content: "";
  height: 2px;
  width: 18px;
  background: #ec1839;
  position: absolute;
  top: -6px;
  left: 0;
}

.nav-toggler span::after {
  content: "";
  height: 2px;
  width: 18px;
  background: #ec1839;
  position: absolute;
  bottom: -6px;
  left: 0;
}

.aside .nav {
  margin-top: 50px;
}

.aside .nav li {
  margin-bottom: 20px;
  display: block;
}

.aside .nav li a {
  font-size: 16px;
  font-weight: 500;
  display: block;
  border-bottom: 2px solid #e8dfec;
  color: #302e4d;
  padding: 5px 15px;
}

.aside .nav li a svg {
  margin-right: 15px;
}

.github-link {
  text-align: center;
  position: relative;
  bottom: -200px;
}

.github-link a {
  color: #302e4d;
  font-size: 35px;
}

#dark .section {
  background: #151515;
}
#dark .section-title h2 {
  color: #ffffff;
}
#dark .section-title h2::before {
  background-color: #fa5b0f;
}
#dark .section-title h2::after {
  background-color: #fa5b0f;
}
#dark .aside {
  background: #222222;
  border-right: 1px solid #393939;
}
#dark .aside .logo a {
  color: #ffffff;
}
#dark .aside .logo a::before {
  border-bottom: 5px solid #fa5b0f;
  border-left: 5px solid #fa5b0f;
}
#dark .aside .logo a::after {
  border-top: 5px solid #fa5b0f;
  border-right: 5px solid #fa5b0f;
}
#dark .aside .nav li a {
  border-bottom: 2px solid #393939;
  color: #ffffff;
}
#dark .nav-toggler {
  background-color: #222222;
  border: 1px solid #393939;
}
#dark .nav-toggler span,
#dark .nav-toggler span::after,
#dark .nav-toggler span::before {
  background-color: #fa5b0f;
}
#dark .github-link a {
  color: #ffffff;
}

.home {
  min-height: 100vh;
  display: flex;
  color: #302e4d;
}

.home .home-info {
  flex: 0 0 60%;
  max-width: 60%;
}

h3.hello {
  font-size: 28px;
  margin: 15px 0;
}

h3.hello span {
  font-size: 30px;
  color: #ec1839;
}

h3.my-profession {
  font-size: 30px;
  margin: 15px 0;
}

.typing {
  color: #ec1839;
}

.home-info p {
  margin-bottom: 70px;
  font-size: 20px;
  color: #504e70;
}

.home .home-img {
  flex: 0 0 40%;
  max-width: 40%;
  text-align: center;
  position: relative;
}

.home .home-img::after {
  content: "";
  position: absolute;
  height: 80px;
  width: 80px;
  bottom: 5px;
  border-bottom: 10px solid #ec1839;
  border-right: 10px solid #ec1839;
}

.home .home-img::before {
  content: "";
  position: absolute;
  height: 80px;
  width: 80px;
  left: -50px;
  top: -80px;
  border-top: 10px solid #ec1839;
  border-left: 10px solid #ec1839;
}

.padd-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.home .home-img img {
  margin: auto;
  border-radius: 5px;
  width: 90%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
}

.btn {
  font-size: 15px;
  font-weight: 500p;
  padding: 12px 35px;
  background: #ec1839;
  color: white;
  border-radius: 40px;
  display: inline-block;
  white-space: nowrap;
  transition: all 0.3s ease;
  border: none;
}

.btn:hover {
  transform: scale(1.05);
}

@media (max-width: 900px) {
  .btn {
    margin-bottom: 40px;
  }
}
#dark .home {
  color: #ffffff;
}
#dark h3.hello span {
  color: #fa5b0f;
}
#dark .typing {
  color: #fa5b0f;
}
#dark .home-info p {
  color: #e9e9e9;
}
#dark .home .home-img::after {
  border-bottom: 10px solid #fa5b0f;
  border-right: 10px solid #fa5b0f;
}
#dark .home .home-img::before {
  border-top: 10px solid #fa5b0f;
  border-left: 10px solid #fa5b0f;
}
#dark .btn {
  background: #fa5b0f;
}

.about .about-content {
  flex: 0 0 100%;
  max-width: 100%;
}

.about .about-content .about-text {
  flex: 0 0 100%;
  max-width: 100%;
}

.about .about-content .about-text h3 {
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 700;
  color: #302e4d;
}

.about .about-content .about-text h3 span {
  color: #ec1839;
}

.about .about-content .about-text p {
  font-size: 16px;
  line-height: 25px;
  color: #504e70;
}

.about .about-content .personal-info {
  flex: 0 0 60%;
  max-width: 60%;
  margin-top: 40px;
}

.about .about-content .personal-info .info-item {
  flex: 0 0 50%;
  max-width: 50%;
}

.about .about-content .personal-info .info-item p {
  font-weight: 600;
  padding: 10px 0;
  font-size: 16px;
  color: #302e4d;
  border-bottom: 2px solid #e8dfec;
}

.about .about-content .personal-info .info-item p span {
  font-weight: 400;
  color: #504e70;
  margin-left: 4px;
  display: inline-block;
}

.about .about-content .personal-info .buttons {
  margin-top: 30px;
}

.about .about-content .personal-info .buttons .btn {
  margin-top: 10px;
}

.about .about-content .skills {
  flex: 0 0 40%;
  max-width: 40%;
  margin-top: 40px;
}

.about .about-content .skills .skill-item {
  flex: 0 0 100%;
  max-width: 100%;
}

.about .about-content .skills .skill-item h5 {
  line-height: 40px;
  font-weight: 600;
  font-size: 16px;
  color: #302e4d;
  text-transform: capitalize;
}

.about .about-content .skills .skill-item .progress {
  background-color: #e8dfec;
  height: 7px;
  border-radius: 4px;
  width: 100%;
  position: relative;
}

.about .about-content .skills .skill-item {
  margin-bottom: 25px;
}

.about .about-content .skills .skill-item .progress .progress-in {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-radius: 4px;
  background-color: #ec1839;
}

.about .about-content .skills .skill-item .skill-percent {
  position: absolute;
  right: 0;
  color: #302e4d;
  top: -40px;
  font-weight: 400;
  line-height: 40px;
}

.about .about-content .education,
.about .about-content .experience {
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 30px;
}

.about .about-content h3.title {
  font-size: 24px;
  margin-bottom: 30px;
  font-weight: 700;
  color: #302e4d;
}

.about .about-content .timeline-box {
  flex: 0 0 100%;
  max-width: 100%;
}

.about .about-content .timeline {
  box-shadow: rgba(214, 214, 214, 0.25) 0px 2px 5px 2px, rgba(158, 155, 155, 0.3) 2px 1px 7px 3px;
  background-color: #fdf9ff;
  padding: 30px 15px;
  border: 1px solid #e8dfec;
  border-radius: 10px;
  width: 100%;
  position: relative;
}

.about .about-content .timeline .timeline-item {
  position: relative;
  padding-left: 37px;
  padding-bottom: 50px;
}

.about .about-content .timeline .timeline-item:last-child {
  padding-bottom: 0;
}

.about .about-content .timeline .timeline-item::before {
  content: "";
  width: 2px;
  position: absolute;
  height: 100%;
  left: 7px;
  top: 0;
  background-color: #ec1839;
}

.about .about-content .timeline .circle-dot {
  position: absolute;
  left: 0;
  top: 0;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #ec1839;
}

.about .about-content .timeline .timeline-date {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 12px;
  color: #504e70;
}

.about .about-content .timeline .timeline-date .fa {
  margin-right: 5px;
}

.about .about-content .timeline .timeline-title {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: capitalize;
  color: #302e4d;
}

.about .about-content .timeline .timeline-text {
  line-height: 25px;
  font-size: 16px;
  text-align: justify;
  color: #504e70;
}

#dark .about .about-content .about-text h3 {
  color: #ffffff;
}
#dark .about .about-content .about-text h3 span {
  color: #fa5b0f;
}
#dark .about .about-content .about-text p {
  color: #e9e9e9;
}
#dark .about .about-content .personal-info .info-item p {
  color: #ffffff;
  border-bottom: 2px solid #393939;
}
#dark .about .about-content .personal-info .info-item p span {
  color: #e9e9e9;
}
#dark .about .about-content .skills .skill-item h5 {
  color: #ffffff;
}
#dark .about .about-content .skills .skill-item .progress {
  background-color: #393939;
}
#dark .about .about-content .skills .skill-item .progress .progress-in {
  background-color: #fa5b0f;
}
#dark .about .about-content .skills .skill-item .skill-percent {
  color: #ffffff;
}
#dark .about .about-content h3.title {
  color: #ffffff;
}
#dark .about .about-content .timeline {
  background-color: #222222;
  border: 1px solid #393939;
}
#dark .about .about-content .timeline .timeline-item::before {
  background-color: #fa5b0f;
}
#dark .about .about-content .timeline .circle-dot {
  background-color: #fa5b0f;
}
#dark .about .about-content .timeline .timeline-date {
  color: #e9e9e9;
}
#dark .about .about-content .timeline .timeline-title {
  color: #ffffff;
}
#dark .about .about-content .timeline .timeline-text {
  color: #e9e9e9;
}

.portfolio .container {
  padding-bottom: 40px;
}

.portfolio .portfolio-heading {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 40px;
}

.portfolio .portfolio-heading h2 {
  color: #302e4d;
  font-weight: 500;
}

.portfolio .portfolio-item {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  margin-bottom: 30px;
}

.portfolio .portfolio-item-inner {
  border: 6px solid #fdf9ff;
  border-radius: 10px;
  overflow: hidden;
}

.portfolio .portfolio-item-inner .portfolio-img img {
  width: 100%;
  display: block;
  height: 250px;
}

.project-info {
  background-color: #fdf9ff;
}

.project-info h4 {
  color: #302e4d;
  font-size: 20px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  text-align: center;
  border-bottom: 2px solid #e8dfec;
}

.project-info h5 {
  font-size: 15px;
  margin-top: 10px;
  color: #302e4d;
}

.stack {
  color: #504e70;
  display: flex;
}

.stack-description p {
  padding-right: 15px;
}

.stack li {
  margin-left: 15px;
  padding-right: 5px;
  margin-top: 5px;
}

.stack li::marker {
  color: #ec1839;
}

.stack ul li::before {
  content: "•";
  color: #ec1839;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1.18em;
}

.stack-used li {
  margin-top: 10px;
}

.project-links {
  display: flex;
  justify-content: space-evenly;
  padding: 20px 0;
  font-weight: 500;
}

.project-links a {
  color: #504e70;
  border-bottom: 2px solid #e8dfec;
}

#dark .portfolio .portfolio-item-inner {
  border: 6px solid #222222;
}
#dark .portfolio .portfolio-heading h2 {
  color: #ffffff;
}
#dark .project-info {
  background-color: #222222;
}
#dark .project-info h4 {
  color: #ffffff;
  border-bottom: 2px solid #e8dfec;
}
#dark .project-info h5 {
  color: #ffffff;
}
#dark .stack {
  color: #e9e9e9;
}
#dark .stack li::marker {
  color: #fa5b0f;
}
#dark .stack ul li::before {
  color: #fa5b0f;
}
#dark .project-links a {
  color: #e9e9e9;
  border-bottom: 2px solid #e8dfec;
}

.contact-title {
  color: #ec1839;
  text-align: center;
  font-size: 25px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.contact-sub-title {
  color: #302e4d;
  text-align: center;
  font-size: 15px;
  margin-bottom: 60px;
}

.contact .contact-info-item {
  flex: 0 0 25%;
  max-width: 25%;
  text-align: center;
  margin-bottom: 60px;
}

.contact .contact-info-item .icon {
  display: inline-block;
}

.contact .contact-info-item .icon .fa {
  font-size: 25px;
  color: #ec1839;
}

.contact .contact-info-item h4 {
  font-size: 18px;
  font-weight: 700;
  color: #302e4d;
  text-transform: capitalize;
  margin: 15px 0 5px;
}

.contact .contact-info-item p {
  font-size: 16px;
  line-height: 25px;
  color: #504e70;
  font-weight: 400;
}

.contact .contact-form {
  flex: 0 0 100%;
  max-width: 100%;
}

.contact .contact-form .col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.contact .contact-form .col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.contact .contact-form .form-item {
  margin-bottom: 30px;
}

.contact .contact-form .form-item .form-control {
  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: #fdf9ff;
  border: 1px solid #e8dfec;
  padding: 10px 25px;
  font-size: 16px;
  color: #504e70;
  transition: all 0.3s ease;
}

.contact .contact-form .form-item .form-control:focus {
  box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
}

.contact .contact-form .form-item textarea.form-control {
  height: 140px;
  resize: none;
}

.contact .contact-form .btn {
  height: 50px;
  padding: 0 50px;
}

#dark .contact-title {
  color: #fa5b0f;
}
#dark .contact-sub-title {
  color: #ffffff;
}
#dark .contact .contact-info-item .icon .fa {
  color: #fa5b0f;
}
#dark .contact .contact-info-item h4 {
  color: #ffffff;
}
#dark .contact .contact-info-item p {
  color: #e9e9e9;
}
#dark .contact .contact-form .form-item .form-control {
  background: #222222;
  border: 1px solid #393939;
  color: #e9e9e9;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  line-height: 1.6;
  font-family: "Poppins", sans-serif;
  background-color: #fefefe;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1,
h2 {
  font-weight: bold;
  line-height: 1.2;
}

p {
  margin: 0;
}

img {
  width: 100%;
}

.main-content {
  padding-left: 270px;
}

.container {
  max-width: 1100px;
  width: 100%;
  margin: auto;
}

.section .container {
  padding-top: 60px;
  padding-bottom: 70px;
}

.switch {
  margin-left: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  margin-top: 20px;
  margin-right: 25px;
  font-size: 16px;
  color: white;
  z-index: 1;
  position: relative;
  font-weight: bold;
  margin-bottom: -5px;
}

#light .label {
  color: #302e4d;
}

#dark .MuiSwitch-thumb {
  background-color: #fa5b0f;
}

#dark .active {
  color: #fa5b0f !important;
}

#light .active {
  color: #ec1839;
}

@media (max-width: 1199px) {
  .section .container {
    padding-top: 70px;
  }
  .aside {
    left: 0;
    max-width: 500px;
    box-sizing: border-box;
    width: 100%;
  }
  .desktop {
    display: none;
  }
  .mobile {
    transition: ease-in;
  }
  .nav-toggler {
    display: flex;
    z-index: 11;
    left: 80%;
  }
  .section {
    left: 0;
  }
  .about .about-content .personal-info .info-item p span {
    display: block;
    margin-left: 0;
  }
  .label {
    font-size: 13px;
    margin-top: 10px;
    margin-left: 10px;
  }
  .switch label {
    width: 120px;
  }
  .github-link {
    bottom: -50px;
  }
}
@media (max-width: 991px) {
  .contact .contact-info-item,
  .portfolio .portfolio-item,
  .service .service-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .home .home-info {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .home .home-img {
    flex: none;
    max-width: 100%;
  }
  .home .home-img img {
    height: auto;
  }
  .home .home-img::after {
    display: none;
  }
  .home .home-img::before {
    display: none;
  }
  .switch {
    margin-left: 0;
    position: absolute;
  }
}
@media (max-width: 767px) {
  .contact .contact-form .col-6,
  .contact .contact-info-item,
  .portfolio .portfolio-item,
  .service .service-item,
  .about .about-content .experience,
  .about .about-content .education,
  .about .about-content .skills,
  .about .about-content .personal-info {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .contact .contact-info-item {
    margin-bottom: 10px;
  }
  .section-title {
    margin-bottom: 40px;
  }
  .about .about-content .personal-info .info-item span {
    word-break: break-all;
  }
}/*# sourceMappingURL=styles.css.map */